import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import { GatsbyImage, StaticImage } from "gatsby-plugin-image";

const IndustrySEOImageSection = ({
	heading,
	image,
	imageAlt,
	text1,
	text2,
	smallHeading,
}) => {
	return (
		<section className="py-5 py-lg-7 bg-light-grey">
			<Container>
				<Row className="g-5 g-lg-6">
					<Col lg={6}>
						<h2 className="display-5 pb-4 text-primary">{heading}</h2>
						<div
							style={{
								borderRadius: "15px",
								boxShadow: "0px 3px 99px #00000046",
								overflow: "hidden",
							}}
						>
							<GatsbyImage
								className="w-100 "
								image={image}
								alt={imageAlt}
								objectPosition="top center"
							/>
						</div>
					</Col>

					<Col lg={6}>
						<p>{text1}</p>
						<StaticImage
							className="mt-5 mb-2 position-relative"
							src="../images/Blue-diamond.svg"
							placeholder="blurred"
							quality={100}
							style={{ width: "100px", left: "-2rem" }}
						/>
						<div
							style={{
								borderRadius: "15px",
								boxShadow: "0px 3px 99px #00000026",
								right: "-3rem",
							}}
							className="bg-white p-3 position-relative"
						>
							<h3 className="text-primary">{smallHeading}</h3>
							<p>{text2}</p>
						</div>
					</Col>
				</Row>
			</Container>
		</section>
	);
};

export default IndustrySEOImageSection;
