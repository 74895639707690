import { Link, graphql, useStaticQuery } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import React from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { InlineWidget } from "react-calendly";
import { Helmet } from "react-helmet";
import { AiOutlineAudit } from "react-icons/ai";
import { BsGraphUp } from "react-icons/bs";
import {
	FaFileCode,
	FaGlobe,
	FaMapMarkerAlt,
	FaMobileAlt,
	FaShoppingBasket,
} from "react-icons/fa";
import { IoMdAnalytics } from "react-icons/io";
import { RiPagesLine } from "react-icons/ri";
import IndustryHero from "../components/Industry-hero";
import IndustryContactSection from "../components/industry-contact-section";
import IndustrySEOImageSection from "../components/industry-seo-image-section";
import IndustryWeDeliver from "../components/industry-we-deliver";
import Layout from "../components/layout";
import OSSection from "../components/other-services-section";
import OASection from "../components/our-approach-section";
import ReviewSection from "../components/review-section";

const ServicesForAccountantsPage = () => {
	const data = useStaticQuery(graphql`
		query {
			heroImg: wpMediaItem(title: { eq: "accountant-services" }) {
				altText
				mediaDetails {
					height
					width
				}
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			videos: allWpVideo(
				filter: { videoFields: { seoPage: { in: "Web Design Eastbourne" } } }
				sort: { fields: dateGmt, order: DESC }
			) {
				nodes {
					videoFields {
						url
						seoPage
					}
					title
				}
			}
			sideImg1: wpMediaItem(title: { eq: "Google-image" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}

			oaImg: wpMediaItem(title: { eq: "googleanalytics2-new" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}

			jasonImg: wpMediaItem(title: { eq: "side-image-2" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			bgImg: wpMediaItem(title: { eq: "side-image-2" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			wpSeoPage(title: { eq: "SEO For Accountants" }) {
				contentForHeadTag {
					content
				}
				seo {
					metaDesc
					title
					twitterImage {
						altText
						mediaDetails {
							height
							width
						}
						link
						gatsbyImage(
							width: 1920
							quality: 75
							formats: [WEBP]
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
					opengraphTitle
					opengraphDescription
				}
			}
			icon: wpMediaItem(title: { eq: "icon" }) {
				link
			}
			wpWhatWeDoSection(
				whatWeDoFields: { page: { in: "Services for Accountants" } }
			) {
				whatWeDoFields {
					page
					servicesProvided {
						branding
						consultancy
						eCommerce
						googleAds
						seo
						sustainableWeb
						training
						webDesign
						webDevelopment
					}
				}
			}
			allWpPost(
				filter: { blogFields: { seoPage: { in: "Web Design Eastbourne" } } }
				sort: { fields: dateGmt, order: DESC }
			) {
				nodes {
					slug
					excerpt
					title
					dateGmt
					blogFields {
						category
						keywords
						seoTitle
						featuredImage {
							gatsbyImage(
								width: 1920
								quality: 75
								formats: [WEBP]
								layout: CONSTRAINED
								placeholder: BLURRED
							)
						}
					}
				}
			}
			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;
	const schema = data.wpSeoPage.contentForHeadTag.content;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "SEO For Accountants",
				item: {
					url: `${siteUrl}/seo-for-accountants`,
				},
			},
		],
	};
	function convertToSlug(Text) {
		return Text.toLowerCase()
			.replace(/ /g, "-")
			.replace(/[^\w-]+/g, "");
	}

	return (
		<>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<Helmet>
				{" "}
				<script type="application/ld+json">{schema}</script>
			</Helmet>
			{/* <LocalBusinessSchema /> */}
			<GatsbySeo
				title={data.wpSeoPage.seo.title}
				description={data.wpSeoPage.seo.metaDesc}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/seo-for-accountants`,
					title: `${data.wpSeoPage.seo.opengraphTitle}`,
					description: `${data.wpSeoPage.seo.opengraphDescription}`,
					images: [
						{
							url: `${data.wpSeoPage.seo.twitterImage.link}`,
							width: `${data.wpSeoPage.seo.twitterImage.mediaDetails.width}`,
							height: `${data.wpSeoPage.seo.twitterImage.mediaDetails.height}`,
							alt: `${data.wpSeoPage.seo.twitterImage.altText}`,
						},
					],
				}}
			/>

			<Layout>
				<section>
					<IndustryHero
						button1="primary-link-dark"
						button1Text="Get in touch"
						button1Link="/contact-us"
						button1Var="white"
						button2={null}
						button3={null}
						backgroundImage={data.heroImg.gatsbyImage}
						title={
							<span>
								TITLE OF
								<span className="text-secondary">INDUSTRY</span>
							</span>
						}
						description="Bespoke digital marketing for growing and ambitious accountancy firms"
					/>
				</section>
				<IndustrySEOImageSection
					image={data.jasonImg.gatsbyImage}
					imageAlt={data.jasonImg.altText}
					heading="SEO FOR ACCOUNTANCY FIRMS"
					smallHeading="SEO CONSULTING"
					text1="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit."
					text2="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua ut enim ad minim veniam."
				/>
				<section className="pt-5 pt-lg-7">
					<Container>
						<Row>
							<Col lg={6}>
								<h2 className="display-5 pb-4 text-primary">
									Get more leads with SEO optimisation
								</h2>
								<p>
									Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
									do eiusmod tempor incididunt ut labore et dolore magna aliqua.
									Ut enim ad minim veniam, quis nostrud exercitation ullamco
									laboris nisi ut aliquip ex ea commodo consequat.
								</p>
								<Button
									className={`mt-4 mb-5 fs-5 w-100 d-none d-md-inline-block w-md-auto py-2 px-4 white-link`}
									variant="primary"
									as={Link}
									to="/contact-us"
								>
									GET IN TOUCH
								</Button>
							</Col>
							<Col lg={6}>
								{" "}
								<StaticImage
									src="../images/results - industry.svg"
									placeholder="blurred"
									quality={100}
									className="w-100"
								/>
							</Col>
						</Row>
					</Container>
				</section>
				<IndustryWeDeliver
					title={
						<span>
							WE DELIVER <span className="text-secondary">RESULTS</span>
						</span>
					}
					backgroundImage={data.bgImg.gatsbyImage}
					item1Heading="Duis aute irure dolorena"
					item1Text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna."
					item2Heading="Duis aute irure dolorena"
					item2Text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna."
					item3Heading="Duis aute irure dolorena"
					item3Text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna."
				/>
				<section className="bg-light-grey py-5 py-lg-7">
					<Container>
						<Row>
							<Col>
								<h2 className="display-5 text-primary mb-5">Types of SEO</h2>
							</Col>
						</Row>
						<Row className="gy-5">
							<Col md={6} lg={4} className="text-md-center">
								<FaMapMarkerAlt className="display-5 text-primary" />
								<h3 className="ssp-bold my-4">Local SEO</h3>
								<p>
									Get noticed by people searching for products and services in
									your local area. Boost your rankings on 'position-zero' and
									Google Maps.
								</p>
							</Col>
							<Col md={6} lg={4} className="text-md-center">
								<FaShoppingBasket className="display-5 text-primary" />
								<h3 className="ssp-bold my-4">E-Commerce SEO</h3>
								<p>
									Optimise each of your product listings and understand gaps in
									the market where you can take advantage of!
								</p>
							</Col>
							<Col md={6} lg={4} className="text-md-center">
								<FaFileCode className="display-5 text-primary" />
								<h3 className="ssp-bold my-4">Technical SEO</h3>
								<p>
									Google not making your site visible on their search results?
									We'll ensure the technical elements of your site are fully
									functioning and immediately boost your rankings!
								</p>
							</Col>
							<Col md={6} lg={4} className="text-md-center">
								<FaGlobe className="display-5 text-primary" />
								<h3 className="ssp-bold my-4">International SEO</h3>
								<p>
									Going Global? We’ll optimise your site so search engines can
									find your business wherever you are and in whatever language.
								</p>
							</Col>
							<Col md={6} lg={4} className="text-md-center">
								<FaMobileAlt className="display-5 text-primary" />
								<h3 className="ssp-bold my-4">Mobile SEO</h3>
								<p>
									Optimise your mobile sites with our mobile UX experts and
									increase your organic traffic and user engagement!
								</p>
							</Col>
							<Col md={6} lg={4} className="text-md-center">
								<RiPagesLine className="display-5 text-primary" />
								<h3 className="ssp-bold my-4">On-Page SEO</h3>
								<p>
									We'll ensure that each of your key landing pages is optimised
									for target keywords optimised and visible to search engines.
								</p>
							</Col>
							<Col md={6} lg={4} className="text-md-center">
								<BsGraphUp className="display-5 text-primary" />
								<h3 className="ssp-bold my-4">SEO Strategy</h3>
								<p>
									If you are finding that your current strategy isn't producing
									the results, we will tailor a bespoke result-driven SEO
									strategy for your business.
								</p>
							</Col>
							<Col lg={4} className="text-md-center">
								<IoMdAnalytics className="display-5 text-primary" />
								<h3 className="ssp-bold my-4">SEO Analytics</h3>
								<p>
									We are result-driven minded in every campaign that we conduct
									with our customers. Analytics is the key component in
									understanding progress and creating plans to achieve the
									result.
								</p>
							</Col>
							<Col md={6} lg={4} className="text-md-center">
								<AiOutlineAudit className="display-5 text-primary" />
								<h3 className="ssp-bold my-4">SEO Audit</h3>
								<p>
									We'll conduct a comprehensive SEO audit of your entire site to
									check, plan and start acting on your new SEO campaign to
									increase your visibility straight away!
								</p>
							</Col>
						</Row>
					</Container>
				</section>
				<OASection
					imgHeight="30rem"
					image={data.oaImg.gatsbyImage}
					imageAlt={data.oaImg.altText}
				/>
				<section className="pt-5 pb-md-5 py-lg-7 bg-light-grey">
					<ReviewSection
						heading="Web Design Eastbourne Reviews"
						page="Our Web Services"
					/>
				</section>
				<section className="pt-5 pt-lg-7 pb-xl-7 bg-light-grey">
					<Container>
						<Row>
							<Col className="text-center">
								{" "}
								<h2 className="display-5 text-primary mb-3">
									Book a discovery meeting
								</h2>
								<p>
									Book a time and day that works for you through our online
									calendar
								</p>
							</Col>
						</Row>
						<Row>
							<Col className="d-xl-none">
								<InlineWidget
									className="calender"
									styles={{ minWidth: "100%", height: "1150px" }}
									url="https://calendly.com/robbie-rjm-digital/rjm-discovery-meeting?hide_gdpr_banner=1"
								/>
							</Col>
							<Col className="d-none d-xl-block">
								<InlineWidget
									className="calender"
									styles={{ minWidth: "100%", height: "700px" }}
									url="https://calendly.com/robbie-rjm-digital/rjm-discovery-meeting?hide_gdpr_banner=1"
								/>
							</Col>
						</Row>
					</Container>
				</section>
				<IndustryContactSection
					title="WE DELIVER RESULTS"
					text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna."
				/>

				<OSSection
					link1="/training"
					text1="TRAINING"
					link3="/web-design"
					text3="WEB DESIGN"
					image={data.sideImg1.gatsbyImage}
					imageAlt={data.sideImg1.altText}
				/>
			</Layout>
		</>
	);
};

export default ServicesForAccountantsPage;
